import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type ClientService from 'vfc-admin/services/client-service';
import type RelationService from 'vfc-admin/services/relation-service';
import type SchoolService from 'vfc-admin/services/school-service';

import type TripService from 'vfc-admin/services/trip-service';

export default class TripIncomplete extends Route {
  @service declare tripService: TripService;
  @service declare schoolService: SchoolService;
  @service declare clientService: ClientService;
  @service declare relationService: RelationService;

  async model(params: { id: string }) {
    // TODO Manage 404 error
    const trip = this.tripService.get(params.id);

    const schoolsPromise = this.schoolService.findAll();
    const clientsPromise = this.clientService.findAll();
    const clientRelationsPromise = this.relationService.findAll();

    return { trip, clientsPromise, schoolsPromise, clientRelationsPromise };
  }
}
