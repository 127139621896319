export const downloadFile = async (response: Response, filename: string) => {
  const blob = await response.blob();
  const URI = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = URI;
  a.download = filename ?? 'no-named.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
