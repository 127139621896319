import { template } from "@ember/template-compiler";
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
export default class ButtonText extends Component<{
    Element: HtmlButtonElement;
    Args: {
        kind: 'primary | outline | outline-inverse | ghost';
        size: 'large | medium | small';
        onClick?: (event: Event) => void;
    };
}> {
    static KIND = {
        primary: 'bg-gradient-to-r from-accent2-200 to-accent2-400 hover:from-accent2-500 hover:to-accent2-500',
        'primary-error': 'bg-error-500 text-white hover:bg-error-700',
        outline: 'bg-transparent border border-neutral-900 hover:bg-neutral-900 hover:text-white',
        'outline-inverse': 'bg-transparent border border-white text-white hover:border-neutral-900 hover:bg-white',
        ghost: 'bg-transparent text-neutral-900 hover:bg-neutral-100'
    };
    static SIZE = {
        large: 'py-[0.875rem] px-8',
        medium: 'py-3 px-6',
        small: 'py-2 px-3'
    };
    get kind() {
        return this.args.kind ?? 'primary';
    }
    get size() {
        return this.args.size ?? 'large';
    }
    @action
    onClick(event1: Event) {
        if (this.args.onClick) this.args.onClick(event1);
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <button
      type="button"
      class="rounded-full {{get ButtonText.KIND this.kind}} {{get ButtonText.SIZE this.size}}"
      {{on 'click' this.onClick}}
      ...attributes>
    {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
