import { template } from "@ember/template-compiler";
import './edit.css';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import { not } from 'ember-truth-helpers';
import Combobox, { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type { IntlService } from 'ember-intl';
import type ClientService from 'vfc-admin/services/client-service';
import type { Trip } from 'vfc-admin/services/trip-service';
import type TripService from 'vfc-admin/services/trip-service';
import type { TODO } from 'vfc-admin/utility-types';
interface IncompleteTripForm {
    clientId?: string;
    clientName?: string;
    clientEmail?: string;
    clientPhone?: string;
    clientRelationId?: string | number;
    schoolId?: string;
    schoolName?: string;
    schoolProvince?: string;
}
interface TripIncompleteEditSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Promise<Trip>;
        clientsPromise: Promise<any[]>;
        schoolsPromise: Promise<any[]>;
        clientRelationsPromise: Promise<any[]>;
    };
}
let TripIncompleteEditComponent = class TripIncompleteEditComponent extends Component<TripIncompleteEditSignature> {
    @service
    clientService: ClientService;
    @service
    tripService: TripService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    @tracked
    clientRadio: string = 'select';
    @tracked
    schoolRadio: string = 'select';
    @tracked
    schools: any[] = [];
    @tracked
    clients: any[] = [];
    @tracked
    clientRelations: any[] = [];
    incompleteTripForm: IncompleteTripForm = {};
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    get tripResource() {
        return awaitPromise<Trip>(Promise.all([
            this.args.schoolsPromise,
            this.args.clientsPromise,
            this.args.clientRelationsPromise,
            this.args.trip
        ]).then((values1)=>{
            const [schools1, clients1, clientRelations1, trip1] = values1;
            this.schools = schools1;
            this.clients = clients1;
            this.clientRelations = clientRelations1;
            const incompleteTripForm1 = trip1?.infoTmp as IncompleteTripForm;
            incompleteTripForm1.clientRelationId = (incompleteTripForm1.clientRelationId || '').toString();
            this.#predictSchool(incompleteTripForm1);
            this.#predictClient(incompleteTripForm1);
            this.incompleteTripForm = incompleteTripForm1;
            return trip1;
        }));
    }
    get schoolsData(): ComboboxItem[] {
        return this.schools.map((school1)=>new ComboboxItem(`${school1.name} (${school1.province})`, school1.id));
    }
    get clientsData(): ComboboxItem[] {
        return this.clients.map((client1)=>new ComboboxItem(`${client1.name} (${client1.email})`, client1.id));
    }
    get isNewClient(): boolean {
        return this.clientRadio === 'new';
    }
    get isNewSchool(): boolean {
        return this.schoolRadio === 'new';
    }
    #predictSchool(incompleteTripForm1: IncompleteTripForm) {
        if (this.schools.length) {
            const school1: any = this.schools.find((school1: any)=>school1.name.toLowerCase() == (incompleteTripForm1.schoolName || '').toLowerCase());
            if (school1) incompleteTripForm1.schoolId = school1.id.toString();
        }
    }
    #predictClient(incompleteTripForm1: IncompleteTripForm) {
        if (this.clients.length && incompleteTripForm1) {
            const client1: any = this.clients.find((client1: any)=>client1.email.toLowerCase() == (incompleteTripForm1.clientEmail || '').toLowerCase());
            if (client1) incompleteTripForm1.clientId = client1.id.toString();
        }
    }
    #formDataToIncompleteTripObj(incompleteTripForm1: IncompleteTripForm) {
        const data1: any = {
            client: {},
            school: {}
        };
        if (this.schoolRadio === 'select') {
            data1.school.id = incompleteTripForm1.schoolId;
        } else {
            data1.school = {
                name: incompleteTripForm1.schoolName,
                province: incompleteTripForm1.schoolProvince
            };
        }
        if (this.clientRadio === 'select') {
            data1.client.id = incompleteTripForm1.clientId;
        } else {
            data1.client = {
                name: incompleteTripForm1.clientName,
                email: incompleteTripForm1.clientEmail,
                phone: incompleteTripForm1.clientPhone
            };
        }
        return data1;
    }
    @action
    onClientRadioChange(event1: Event) {
        this.clientRadio = (event1.target as HTMLInputElement).value;
    }
    @action
    onSchoolRadioChange(event1: Event) {
        this.schoolRadio = (event1.target as HTMLInputElement).value;
    }
    @action
    async onSubmit(data1: FormData<IncompleteTripForm>) {
        const incompleteTrip1 = this.#formDataToIncompleteTripObj(data1 as IncompleteTripForm);
        try {
            await this.tripService.postIncomplete(this.incompleteTripForm.tripId, incompleteTrip1);
            this.router.transitionTo('authenticated.trips.incomplete');
        } catch (error1) {
            console.log(error1);
        }
    }
    static{
        template(`
    {{#if this.tripResource.isPending}}
      Loading
    {{else if this.tripResource.isSuccess}}
      <HeadlessForm
        @data={{this.incompleteTripForm}}
        @onSubmit={{this.onSubmit}}
        @validateOn="focusout"
        @revalidateOn="input"
        as |form|
      >
        <div class="card">
          <div class="card-body">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <div
                  class="border-b border-border dark:border-darkborder chat-meta-user flex items-center justify-between"
                >
                  <h5 class="font-semibold text-dark dark:text-white text-lg">
                    Viaje incompleto
                  </h5>
                </div>
                <div class="grid grid-cols-12 gap-4 mt-4">
                  <div class="lg:col-span-5 md:col-span-12 sm:col-span-12 col-span-12">
                    <div class="flex gap-4">
                      <img
                        src="{{this.tripResource.result.image}}"
                        width="150"
                        class="rounded-sm"
                      />
                      <div>
                        <h6 class="text-base">
                          {{this.tripResource.result.reference}}
                        </h6>
                        <p>
                          {{this.tripResource.result.destination.name}}</p>
                        <p>
                          {{this.tripResource.result.destination.city.name}}
                          (
                          {{this.tripResource.result.destination.city.country.name}})
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="lg:col-span-3 md:col-span-6 sm:col-span-12 col-span-12">
                    <h6 class="text-base">
                      Pasajeros
                    </h6>
                    <p>
                      Alumnos:
                      {{this.tripResource.result.totalStudents}}
                    </p>
                    <p>
                      Profesores:
                      {{this.tripResource.result.totalTeachers}}
                    </p>
                  </div>
                  <div class="lg:col-span-4 md:col-span-6 sm:col-span-12 col-span-12">
                    <h6 class="text-base">
                      Fechas solicitadas
                    </h6>
                    <p>
                      Salida:
                      {{formatDate this.tripResource.result.checkIn}}
                    </p>
                    <p>
                      Llegada:
                      {{formatDate this.tripResource.result.checkOut}}
                    </p>
                  </div>
                </div>
                <div class="pt-5">
                  <div class="items-start justify-between">

                  </div>
                </div>
              </div>
              <div class="lg:col-span-6 col-span-12">

                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Cliente</h5>
                    <div class="mt-3">
                      <input
                        type="radio"
                        name="clientRadio"
                        value="select"
                        class="shrink-0 mt-0.5 w-5 h-5 form-check-input"
                        id="client-radio-select"
                        {{on "click" this.onClientRadioChange}}
                        checked
                      />
                      <label
                        for="client-radio-select"
                        class="text-sm text-gray-500 ms-2 dark:text-gray-400"
                      >
                        Seleccionar cliente
                      </label>

                      <div class="m-5">
                        <form.Field @name="clientId" as |field|>
                          <Combobox
                            @value={{field.value}}
                            @onChange={{field.setValue}}
                            id={{field.id}}
                            @placeholder="-- Selecciona una opción --"
                            @items={{this.clientsData}}
                            @disabled={{this.isNewClient}}
                            @invalid={{field.isInvalid}}
                            name="clientId"
                            required
                            {{on "invalid" this.validateInputNatively}}
                          />

                          <field.Errors class="py-1 text-xs text-red-300" />
                        </form.Field>
                      </div>
                    </div>
                    <div class="mt-3">
                      <input
                        type="radio"
                        name="clientRadio"
                        value="new"
                        class="shrink-0 mt-0.5 w-5 h-5 form-check-input"
                        id="client-radio-new"
                        {{on "click" this.onClientRadioChange}}
                      />
                      <label
                        for="client-radio-new"
                        class="text-sm text-gray-500 ms-2 dark:text-gray-400"
                      >
                        Crear cliente
                      </label>

                      <div class="m-5">
                        <div class="card">
                          <div class="card-body">
                            <div class="flex flex-col gap-4">
                              <div>
                                <form.Field @name="clientName" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Nombre
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Input
                                    required
                                    maxLength="255"
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                                    disabled={{not this.isNewClient}}
                                  />
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                              <div>
                                <form.Field @name="clientEmail" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Email
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Input
                                    type="email"
                                    required
                                    maxLength="255"
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2"
                                    disabled={{not this.isNewClient}}
                                  />
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                              <div>
                                <form.Field @name="clientPhone" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Teléfono
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Input
                                    required
                                    maxLength="255"
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                                    disabled={{not this.isNewClient}}
                                  />
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                              <div>
                                <form.Field @name="clientRelationId" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Relación
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Select
                                    required
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                                    disabled={{not this.isNewClient}}
                                    as |select|
                                  >
                                    <select.Option @value="">
                                      -- Selecciona una opción --
                                    </select.Option>
                                    {{#each this.clientRelations as |clientRelation|}}
                                      <select.Option @value="{{clientRelation.name}}" selected>
                                        {{clientRelation.name}}
                                      </select.Option>
                                    {{/each}}
                                    <select.Option @value="male">
                                      Masculino
                                    </select.Option>
                                  </field.Select>
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:col-span-6 col-span-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Colegio</h5>
                    <div class="mt-3">
                      <input
                        type="radio"
                        name="schoolRadio"
                        value="select"
                        class="shrink-0 mt-0.5 w-5 h-5 form-check-input"
                        id="school-radio-select"
                        {{on "click" this.onSchoolRadioChange}}
                        checked
                      />
                      <label
                        for="school-radio-select"
                        class="text-sm text-gray-500 ms-2 dark:text-gray-400"
                      >
                        Seleccionar colegio
                      </label>

                      <div class="m-5">
                        <form.Field @name="schoolId" as |field|>
                          <Combobox
                            @value={{field.value}}
                            @onChange={{field.setValue}}
                            id={{field.id}}
                            @placeholder="-- Selecciona una opción --"
                            @items={{this.schoolsData}}
                            @disabled={{this.isNewSchool}}
                            @invalid={{field.isInvalid}}
                            name="schoolId"
                            required
                            {{on "invalid" this.validateInputNatively}}
                          />

                          <field.Errors class="py-1 text-xs text-red-300" />
                        </form.Field>
                      </div>
                    </div>
                    <div class="mt-3">
                      <input
                        type="radio"
                        name="schoolRadio"
                        value="new"
                        class="shrink-0 mt-0.5 w-5 h-5 form-check-input"
                        id="school-radio-new"
                        {{on "click" this.onSchoolRadioChange}}
                      />
                      <label
                        for="school-radio-new"
                        class="text-sm text-gray-500 ms-2 dark:text-gray-400"
                      >
                        Crear colegio
                      </label>

                      <div class="m-5">
                        <div class="card">
                          <div class="card-body">
                            <div class="flex flex-col gap-4">
                              <div>
                                <form.Field @name="schoolName" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Nombre
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Input
                                    required
                                    maxLength="255"
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                                    disabled={{not this.isNewSchool}}
                                  />
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                              <div>
                                <form.Field @name="schoolProvince" as |field|>
                                  <field.Label
                                    class="text-dark dark:text-darklink font-semibold mb-2 block"
                                  >
                                    Provincia
                                    <span class="text-red-500">*</span>
                                  </field.Label>
                                  <field.Input
                                    required
                                    maxLength="255"
                                    {{on "invalid" this.validateInputNatively}}
                                    class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                                    disabled={{not this.isNewSchool}}
                                  />
                                  <field.Errors class="py-1 text-xs text-red-300" />
                                </form.Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="flex gap-3 justify-end">
                  <button class="btn btn-md" type="submit">Completar</button>
                  <a
                    class="btn btn-light-error"
                    href="{{urlForRoute 'authenticated.trips.incomplete'}}"
                  >Cancelar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeadlessForm>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            trip: Promise<TODO>;
            clientsPromise: Promise<TODO>;
            schoolsPromise: Promise<TODO>;
            clientRelationsPromise: Promise<TODO>;
        };
    };
}>(template(`
    <TripIncompleteEditComponent
      @trip={{@model.trip}}
      @clientsPromise={{@model.clientsPromise}}
      @schoolsPromise={{@model.schoolsPromise}}
      @clientRelationsPromise={{@model.clientRelationsPromise}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
