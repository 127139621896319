import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type TripService from 'vfc-admin/services/trip-service';

export default class TripPending extends Route {
  @service declare tripService: TripService;

  async model() {
    const trips = this.tripService.pending();

    return {
      trips,
    };
  }
}
