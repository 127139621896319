import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import Combobox from 'vfc-admin/components/inputs/combobox/combobox';
import { urlForRoute } from 'vfc-admin/helpers/url-for-route';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import type { ClientForm } from 'vfc-admin/models/client';
export interface ClientFormComponentSignature {
    Element: HTMLElement;
    Args: {
        client: ClientForm;
        clientRelations: ComboboxItem[];
        schools: ComboboxItem[];
        onSubmit?: (data: FormData<ClientForm>) => void;
    };
}
export default class ClientFormComponent extends Component<ClientFormComponentSignature> {
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    static{
        template(`
    <HeadlessForm
      @data={{@client}}
      @onSubmit={{@onSubmit}}
      @validateOn="focusout"
      @revalidateOn="input"
      as |form|
    >
      <div class="grid grid-cols-12 gap-x-6 gap-y-4 mt-5">
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="username" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Usuario
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="password" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Contraseña
                </field.Label>
                <field.Input
                  type="password"
                  maxLength="255"
                  placeholder="Contraseña"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
                <div class="text-xs text-dark dark:text-darklink mt-1 flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-info-circle"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#5D87FF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                  </svg>Dejar en blanco para no cambiar la contraseña
                </div>
              </form.Field>
            </div>
            <div>
              <form.Field @name="email" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Email
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  type="email"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="clientRelationId" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Relación
                  <span class="text-red-500">*</span>
                </field.Label>
                <Combobox
                  @value={{field.value}}
                  @onChange={{field.setValue}}
                  id={{field.id}}
                  @placeholder="-- Selecciona una opción --"
                  @items={{@clientRelations}}
                  @invalid={{field.isInvalid}}
                  name="clientRelationId"
                  required
                  {{on "invalid" this.validateInputNatively}}
                />

                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>

          </div>
        </div>
        <div class="lg:col-span-6 col-span-12">
          <div class="flex flex-col gap-4">
            <div>
              <form.Field @name="name" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Nombre
                  <span class="text-red-500">*</span>
                </field.Label>
                <field.Input
                  required
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="phone" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Teléfono
                </field.Label>
                <field.Input
                  maxLength="255"
                  {{on "invalid" this.validateInputNatively}}
                  class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                />
                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
            <div>
              <form.Field @name="schoolId" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                  Colegio
                  <span class="text-red-500">*</span>
                </field.Label>
                <Combobox
                  @value={{field.value}}
                  @onChange={{field.setValue}}
                  id={{field.id}}
                  @placeholder="-- Selecciona una opción --"
                  @items={{@schools}}
                  @invalid={{field.isInvalid}}
                  name="schoolId"
                  required
                  {{on "invalid" this.validateInputNatively}}
                />

                <field.Errors class="py-1 text-xs text-red-300" />
              </form.Field>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <div class="flex gap-3 justify-end">
            <button class="btn btn-md" type="submit">Completar</button>
            <a class="btn btn-light-error" href="{{urlForRoute 'authenticated.clients'}}">
              Cancelar
            </a>
          </div>
        </div>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
