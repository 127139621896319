import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';
import type TripService from 'vfc-admin/services/trip-service';

export default class TripView extends Route {
  @service('router') declare router: RouterService;
  @service declare tripService: TripService;

  async model(params: { id: string }) {
    const tripId = params.id;
    const tripPromise = this.tripService.get(tripId);

    return { tripId, tripPromise };
  }
}
