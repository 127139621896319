import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type ClientService from 'vfc-admin/services/client-service';

export default class Client extends Route {
  @service declare clientService: ClientService;

  async model() {
    const clientsPromise = this.clientService.findAll();

    return { clientsPromise };
  }
}
