import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import SchoolFormComponent from 'vfc-admin/components/forms/schoolForm';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type { School, SchoolForm } from 'vfc-admin/models/school';
import type SchoolService from 'vfc-admin/services/school-service';
interface SchoolEditSignature {
    Element: HTMLDivElement;
    Args: {
        schoolPromise: Promise<School>;
    };
}
let SchoolEditComponent = class SchoolEditComponent extends Component<SchoolEditSignature> {
    @service
    schoolService: SchoolService;
    @service
    router: RouterService;
    get schoolResource() {
        return awaitPromise<School>(this.args.schoolPromise);
    }
    get school() {
        return this.schoolResource.result as School;
    }
    @action
    async onSubmit(data1: FormData<SchoolForm>) {
        try {
            await this.schoolService.update(this.school.id, data1);
            this.router.transitionTo('authenticated.schools');
        } catch (error1) {
            console.log(error1);
        }
    }
    static{
        template(`
    {{#if this.schoolResource.isPending}}
      Loading
    {{else if this.schoolResource.isSuccess}}
      <div class="card">
        <div class="col-span-12">
          <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
            <h5 class="card-title mb-0"> Colegio: {{this.school.name}}</h5>
          </div>
        </div>
        <div class="card-body">
          <SchoolFormComponent @school={{this.school}} @onSubmit={{this.onSubmit}} />
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            schoolPromise: Promise<School>;
        };
    };
}>(template(`<SchoolEditComponent @schoolPromise={{@model.schoolPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
