import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import onResize from 'vfc-admin/modifiers/on-resize';
import type Owner from '@ember/owner';
import type { Args } from '@glimmer/component/-private/component';
import type ThemeService from 'vfc-admin/services/theme-service';
interface ApplicationRouteSignature {
}
let ApplicationRouteComponent = class ApplicationRouteComponent extends Component<ApplicationRouteSignature> {
    @service
    themeService: ThemeService;
    constructor(owner1: Owner, args1: Args<ApplicationRouteSignature>){
        super(owner1, args1);
        const htmlTag1 = document.documentElement;
        const boxedLayout1 = this.themeService.boxedLayout ? 'boxed' : 'full';
        const width1 = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
        const sidebarType1 = width1 < 1300 ? 'mini-sidebar' : this.themeService.sidebarType;
        htmlTag1.setAttribute('data-color-theme', this.themeService.colorTheme);
        htmlTag1.setAttribute('data-boxed-layout', boxedLayout1);
        htmlTag1.setAttribute('dir', this.themeService.direction);
        htmlTag1.setAttribute('data-card', this.themeService.cardBorder ? 'border' : 'shadow');
        document.body.setAttribute('data-sidebartype', sidebarType1);
    }
    @action
    onResize() {
        const width1 = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
        const sidebarType1 = width1 < 1300 ? 'mini-sidebar' : this.themeService.sidebarType;
        document.body.setAttribute('data-sidebartype', sidebarType1);
    }
    static{
        template(`
    {{pageTitle "VfcAdmin"}}

    <main {{onResize this.onResize}}>
      <div id="main-wrapper" class="flex">
        {{outlet}}
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ApplicationRouteComponent);
