import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';
import { type ClientForm } from 'vfc-admin/models/client';

import type { Client } from 'vfc-admin/models/client';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';

export default class ClientService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    findAll: '/client',
    get: '/client/:id',
  };

  findAll(): Promise<Client[]> {
    return this.authenticatedHttpClient.GET<Client[]>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${ClientService.ENDPOINTS.findAll}`,
    });
  }

  get(id: string): Promise<Client> {
    const endpoint = ClientService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Client>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  create(body: ClientForm): Promise<void> {
    const endpoint = ClientService.ENDPOINTS.findAll;
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<void>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }

  update(id: string, body: ClientForm): Promise<Client> {
    const endpoint = ClientService.ENDPOINTS.get.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<Client>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      body,
      headers,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
