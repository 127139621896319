import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ModifierLike } from '@glint/template';
import { Popover } from 'ember-primitives';
import { and } from 'ember-truth-helpers';
export default class Tooltip extends Component<{
    Element: HTMLDivElement;
    Args: {
        showArrow?: boolean;
        inline?: boolean;
        placement?: 'top' | 'right' | 'bottom' | 'left' | 'top-start' | 'top-end' | 'right-start' | 'right-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end';
        showIfPassCondition: () => boolean;
    };
    Blocks: {
        tooltipable: [{
                hook: ModifierLike<unknown>;
            }];
        content: [];
    };
}> {
    @tracked
    shown = false;
    @action
    show() {
        this.shown = true;
    }
    @action
    hide() {
        this.shown = false;
    }
    get placement() {
        return this.args.placement ?? 'top';
    }
    get showIfPassCondition() {
        return this.args.showIfPassCondition?.() ?? true;
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    {{!TODO: investigate why PortalTargets is not working as expected and the @inline prop needs to be used}}
    <Popover @placement={{this.placement}} @offsetOptions={{8}} @inline={{@inline}} as |p|>
      <div {{on "mouseenter" this.show}} {{on "mouseleave" this.hide}}>
        {{yield p to="tooltipable"}}
      </div>
      <p.Content @as={{"tooltip"}}>
        {{#if (and this.shown this.showIfPassCondition)}}
          <div class="rounded-lg bg-neutral-900 text-white py-2 px-3">
            {{yield to="content"}}
          </div>
          {{#if @showArrow}}
            <div {{p.arrow}} class="absolute bg-neutral-900 w-2 h-2 rotate-45"></div>
          {{/if}}
        {{/if}}
      </p.Content>
    </Popover>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
