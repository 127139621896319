import Route from '@ember/routing/route';
import { service } from '@ember/service';

import Configuration from 'ember-simple-auth/configuration';

import type { IntlService } from 'ember-intl';
import type { Session } from 'ember-simple-auth/services/session';

export default class Application extends Route {
  @service declare session: Session;
  @service declare intl: IntlService;

  async beforeModel() {
    Configuration.load({ routeAfterAuthentication: 'authenticated' });
    await this.session.setup();
    this.intl.setLocale(['es-es']);
  }
}
