import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import TripFormComponent from 'vfc-admin/components/forms/tripForm';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { formatDate } from 'vfc-admin/utils/date-utils';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type { Args } from '@glimmer/component/-private/component';
import type { IntlService } from 'ember-intl';
import type TripService from 'vfc-admin/services/trip-service';
import type { Trip, TripForm } from 'vfc-admin/types/trip';
interface TripCurrentCourseEditSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Promise<Trip>;
    };
}
let TripCurrentCourseEditComponent = class TripCurrentCourseEditComponent extends Component<TripCurrentCourseEditSignature> {
    @service
    tripService: TripService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    get tripResource() {
        return awaitPromise<Trip>(this.args.trip);
    }
    get formData(): TripForm {
        const trip1 = this.tripResource.result;
        if (!trip1) return {} as TripForm;
        return {
            id: trip1.id,
            state: trip1.state,
            totalStudents: trip1.totalStudents,
            totalRequestedStudents: trip1.requestedStudents,
            totalTeachers: trip1.totalTeachers,
            totalRequestedTeachers: trip1.requestedTeachers,
            image: trip1.image,
            reference: trip1.reference,
            client_id: trip1?.client?.id,
            client_name: trip1?.client?.name,
            client_email: trip1?.client?.email,
            client_school_id: trip1?.client?.school?.id,
            client_school_name: trip1?.client?.school?.name,
            client_school_province: trip1?.client?.school?.province,
            client_relationship: trip1?.client?.clientRelation?.name,
            destination_id: trip1?.destination?.id,
            destination_name: trip1?.destination?.name,
            destination_city_id: trip1?.destination?.city?.id,
            destination_city_name: trip1?.destination?.city?.name,
            accept_conditions: isPresent(trip1.acceptedDate) ? 'SI' : 'NO',
            accepted_date: trip1.acceptedDate ? formatDate(trip1.acceptedDate, config.APP.DATE.DATE_TYPE_FORMAT) : '',
            modifyClientData: trip1.modifyClientData,
            requestedCheckIn: formatDate(trip1.requestedCheckIn, config.APP.DATE.DATE_TYPE_FORMAT),
            checkIn: formatDate(trip1.checkIn, config.APP.DATE.DATE_TYPE_FORMAT),
            requestedCheckOut: formatDate(trip1.requestedCheckOut, config.APP.DATE.DATE_TYPE_FORMAT),
            checkOut: formatDate(trip1.checkOut, config.APP.DATE.DATE_TYPE_FORMAT),
            groupAge: trip1.groupAge,
            totalPrice: trip1.totalPrice,
            anythingElse: trip1.anythingElse,
            observations: trip1.observations ?? ''
        };
    }
    @action
    async onSubmit(data1: FormData<TripForm>) {
        try {
            await this.tripService.update(String(data1.id), data1 as TripForm);
            this.router.transitionTo('authenticated.trips.current-course');
        } catch (error1) {
            console.error(error1);
        }
    /*
    const incompleteTrip = this.#formDataToIncompleteTripObj(data as IncompleteTripForm);

    try {
      await this.tripService.postIncomplete(this.incompleteTripForm.tripId, incompleteTrip);
      this.router.transitionTo('authenticated.trips.incomplete');
    } catch (error) {
      console.log(error);
    }
    */ }
    onResetForm = ()=>{
    //reset form
    };
    constructor(owner1: Owner, args1: Args<TripCurrentCourseEditSignature>){
        super(owner1, args1);
    }
    static{
        template(`
    {{#if this.tripResource.isPending}}
      Loading
    {{else if this.tripResource.isSuccess}}
      <TripFormComponent
        @tripForm={{this.formData}}
        @onSubmit={{this.onSubmit}}
        @onResetForm={{this.onResetForm}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
        };
    };
}>(template(`<TripCurrentCourseEditComponent @trip={{@model.tripPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
