import type { ClientRelation } from './ClientRelation';
import type { School } from './school';

export interface ClientInterface {
  id: number;
  username?: string;
  password?: string;
  name: string;
  phone?: string;
  email: string;
  school: School;
  clientRelation: ClientRelation;
  createdAt?: Date;
  updatedAt?: Date;
}

export class Client implements ClientInterface {
  id!: number;
  username?: string;
  password?: string;
  name!: string;
  phone?: string;
  email!: string;
  school!: School;
  clientRelation!: ClientRelation;
  createdAt?: Date;
  updatedAt?: Date;

  static parse(data: ClientInterface): Client {
    const client = new Client();

    client.id = data.id;
    client.username = data.username || undefined;
    client.password = data.password || undefined;
    client.name = data.name;
    client.phone = data.phone || undefined;
    client.email = data.email;
    client.school = data.school; // TODO
    client.clientRelation = data.clientRelation;
    client.createdAt = data.createdAt || undefined;
    client.updatedAt = data.updatedAt || undefined;

    return client;
  }
}

export type ClientForm = Omit<Client, 'id' | 'createdAt' | 'updatedAt'> & {
  clientRelationId: number | undefined;
  schoolId: number | undefined;
};
