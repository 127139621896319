import type Owner from '@ember/owner';
import { getOwner } from '@ember/owner';

const validateInputNatively = function (event: Event) {
  const input = event.target as HTMLInputElement;
  const validityState = input.validity;
  const owner = getOwner(this);
  const intl = (owner as Owner).lookup('service:intl');

  if (validityState.valueMissing) {
    input.setCustomValidity(intl.t('common.form.validation.valueMissing'));
  } else if (validityState.patternMismatch) {
    input.setCustomValidity(intl.t('common.form.validation.patternMismatch'));
  } else if (validityState.tooShort) {
    input.setCustomValidity(
      intl.t('common.form.validation.tooShort', { num: input.minLength.toString() })
    );
  } else if (validityState.tooLong) {
    input.setCustomValidity(
      intl.t('common.form.validation.tooLong', { num: input.minLength.toString() })
    );
  } else if (validityState.badInput) {
    if (input.type === 'date') {
      input.setCustomValidity(intl.t('common.form.validation.badInput.date'));
    }
  } else if (validityState.rangeOverflow) {
    input.setCustomValidity(
      intl.t('common.form.validation.rangeOverflow', { max: input.max.toString() })
    );
  } else if (validityState.rangeUnderflow) {
    input.setCustomValidity(
      intl.t('common.form.validation.rangeUnderflow', { min: input.min.toString() })
    );
  } else {
    input.setCustomValidity('');
  }
};

export { validateInputNatively };
