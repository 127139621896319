import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { service } from '@ember/service';
import { headlessTable } from 'ember-headless-table';
import RouteTemplate from 'ember-route-template';
import TableSkeletonLoader from 'vfc-admin/components/ui/table-skeleton-loader';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type { TOC } from '@ember/component/template-only';
import type { Column, Row } from 'ember-headless-table';
import type { IntlService } from 'ember-intl';
import { forColumn, Metadata } from 'ember-headless-table/plugins/metadata';
import config from 'vfc-admin/config/environment';
import { formatDate } from 'vfc-admin/utils/date-utils';
import type { School } from 'vfc-admin/models/school';
import type { Client } from 'vfc-admin/models/client';
interface ClientTableSignature {
    Element: HTMLDivElement;
    Args: {
        clientsPromise: Promise<Client[]>;
    };
}
const INTL_COLUMNS_PREFIX = 'page.clients.table.columns';
const isFormatDate = (column1: Column<School>)=>{
    return forColumn(column1, 'formatDate');
};
const SchoolCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a href="/schools/{{get @row.data 'school.id'}}" class="text-info edit">
      {{get @row.data "school.name"}}
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a href="/clients/{{get @row.data 'id'}}" class="text-info edit">
      <i class="ti ti-pencil text-lg"></i>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let ClientTableComponent = class ClientTableComponent extends Component<ClientTableSignature> {
    @service
    intl: IntlService;
    get clientsResource() {
        return awaitPromise<Client[]>(this.args.clientsPromise);
    }
    clientsTable = headlessTable<Client>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.name`),
                    key: 'name'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.phone`),
                    key: 'phone'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.email`),
                    key: 'email'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.school`),
                    key: 'school',
                    Cell: SchoolCellComponent
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.updatedAt`),
                    key: 'updatedAt',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_DATETIME_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent
                }
            ],
        data: ()=>{
            return this.clientsResource.result ?? [];
        }
    });
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">
        <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
          <h5 class="card-title mb-0">Clientes</h5>
          <a
            href="/clients/create"
            class="btn-md text-sm font-semibold rounded-md border border-primary text-primary hover:bg-primary hover:text-white"
            type="button"
          >
            Nuevo<i class="ti ti-plus text-sm"></i>
          </a>
        </div>

        <div class="card-body">
          <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="overflow-hidden">
                  <table
                    class="min-w-full divide-y divide-border dark:divide-darkborder"
                    {{this.clientsTable.modifiers.container}}
                  >
                    <thead>
                      <tr>
                        {{#each this.clientsTable.columns as |column|}}
                          <th
                            scope="col"
                            class="text-left rtl:text-right p-4 ps-0 font-semibold text-dark dark:text-white"
                          >
                            {{column.name}}
                          </th>
                        {{/each}}
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-border dark:divide-darkborder">
                      {{#if this.clientsResource.isPending}}
                        <TableSkeletonLoader @rows={{8}} @columns={{7}} @columnClass={{"py-3"}} />
                      {{else if this.clientsResource.isError}}
                        <tr class="text-center text-red-500">
                          <td colspan="7">
                            <div>Unexpected error has happened</div>
                            <div class="text-sm">{{this.clientsResource.error.message}}</div>
                          </td>
                        </tr>
                      {{else}}
                        {{#each this.clientsTable.rows as |row|}}
                          <tr>
                            {{#each this.clientsTable.columns as |column|}}
                              <td class="text-dark dark:text-darklink text-base font-light py-3">
                                {{#if column.Cell}}
                                  <column.Cell @row={{row}} @column={{column}} />
                                {{else}}
                                  {{#let (isFormatDate column) as |formatDateValue|}}
                                    {{#if formatDateValue}}
                                      {{formatDate (column.getValueForRow row) formatDateValue}}
                                    {{else}}
                                      {{column.getValueForRow row}}
                                    {{/if}}
                                  {{/let}}
                                {{/if}}
                              </td>
                            {{/each}}
                          </tr>
                        {{/each}}
                      {{/if}}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            clientsPromise: Promise<Client[]>;
        };
    };
}>(template(`<ClientTableComponent @clientsPromise={{@model.clientsPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
