import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RouteTemplate from 'ember-route-template';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type TripDocumentsService from 'vfc-admin/services/trip-documents-service';
import type { Trip } from 'vfc-admin/types/trip';
let FilesState = class FilesState {
    @tracked
    budgetFile?: File;
    @tracked
    budgetFileName?: string;
    @tracked
    proformaFile?: File;
    @tracked
    proformaFileName?: string;
    @tracked
    contractFile?: File;
    @tracked
    contractFileName?: string;
};
interface DocumentsSignature {
    Element: HTMLDivElement;
    Args: {
        tripPromise: Promise<Trip>;
    };
}
let Documents = class Documents extends Component<DocumentsSignature> {
    @tracked
    filesState = new FilesState();
    @service
    tripDocumentsService: TripDocumentsService;
    get downloadFunctions() {
        return {
            summary: this.tripDocumentsService.downloadSummaryPDF.bind(this.tripDocumentsService),
            contract: this.tripDocumentsService.downloadContractPDF.bind(this.tripDocumentsService),
            proforma: this.tripDocumentsService.downloadProformaPDF.bind(this.tripDocumentsService)
        } as Record<string, (tripId: string) => Promise<Response>>;
    }
    get tripResource() {
        return awaitPromise<Trip>(this.args.tripPromise);
    }
    get budget() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.budgetFileName ?? this.tripResource?.result?.summaryPdf,
            file: this.filesState.budgetFile
        };
    }
    get proforma() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.proformaFileName ?? this.tripResource?.result?.proforma,
            file: this.filesState.proformaFile
        };
    }
    get contract() {
        if (!this.tripResource.isSuccess) return;
        return {
            name: this.filesState.contractFileName ?? this.tripResource?.result?.contract,
            file: this.filesState.contractFile
        };
    }
    get tripId() {
        if (!this.tripResource.isSuccess) return;
        return String(this.tripResource?.result?.id);
    }
    @action
    onFileChange(event1: Event) {
        const target1 = event1.target as HTMLInputElement;
        const files1 = target1.files;
        if (files1 && files1.length > 0) {
            const file1 = files1[0];
            if (!file1) return;
            switch(target1.id){
                case 'budget-file-upload':
                    this.filesState.budgetFile = file1;
                    this.filesState.budgetFileName = file1?.name;
                    break;
                case 'proforma-file-upload':
                    this.filesState.proformaFile = file1;
                    this.filesState.proformaFileName = file1?.name;
                    break;
                case 'contract-file-upload':
                    this.filesState.contractFile = file1;
                    this.filesState.contractFileName = file1?.name;
            }
            if (!this.tripId) throw new Error('tripId is required');
            this.tripDocumentsService.upload(this.tripId, target1.name, file1);
        }
    }
    @action
    onView(documentType1: string, file1: File | undefined) {
        if (!file1 || !this.tripId) {
            this.downloadFunctions[documentType1](this.tripId).then((response1: Response)=>{
                response1.blob().then((blob1: Blob)=>{
                    const url1 = window.URL.createObjectURL(blob1);
                    window.open(url1, '_blank');
                });
            });
        } else {
            window.open(URL.createObjectURL(file1), '_blank');
        }
    }
    @action
    onDelete(id1: string) {
        switch(id1){
            case 'budget-file-upload':
                this.filesState.budgetFile = undefined;
                this.filesState.budgetFileName = undefined;
                break;
            case 'proforma-file-upload':
                this.filesState.proformaFile = undefined;
                this.filesState.proformaFileName = undefined;
                break;
            case 'contract-file-upload':
                this.filesState.contractFile = undefined;
                this.filesState.contractFileName = undefined;
                break;
        }
    }
    static{
        template(`
    <div class="flex flex-col gap-y-5 my-10">
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="budget-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Presupuesto</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.budget.name}}>
                {{if this.budget.name this.budget.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="budget-file-upload"
            name="summary"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.budget.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "summary" this.budget.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>
            <button {{on "click" (fn this.onDelete "budget-file-upload")}} class="text-red-500">
              <i class="ti ti-trash text-lg"></i>
            </button>
          </div>
        {{/if}}
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="proforma-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Proforma</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.proforma.name}}>
                {{if this.proforma.name this.proforma.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="proforma-file-upload"
            name="proforma"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.proforma.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "proforma" this.proforma.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>
            <button {{on "click" (fn this.onDelete "proforma-file-upload")}} class="text-red-500">
              <i class="ti ti-trash text-lg"></i>
            </button>
          </div>
        {{/if}}
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-4 border border-gray-300 rounded-lg pr-2">
          <label for="contract-file-upload" class="group flex items-center cursor-pointer w-full">
            <span
              class="group-hover:bg-blue-100 flex items-center cursor-pointer min-w-32 bg-blue-50 text-blue-700 font-semibold py-2 px-4 rounded-l-lg"
            >Contrato</span>
            {{#if this.tripResource.isPending}}
              <span
                role="status"
                class="min-w-48 max-w-sm animate-pulse flex items-center justify-center"
              >
                <span class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></span>
              </span>
            {{else if this.tripResource.isSuccess}}
              <span class="ml-2 truncate text-sm px-5 min-w-48" title={{this.contract.name}}>
                {{if this.contract.name this.contract.name "Seleccionar archivo"}}</span>
            {{/if}}
          </label>
          <input
            id="contract-file-upload"
            name="contract"
            type="file"
            accept=".pdf"
            class="hidden"
            {{on "change" this.onFileChange}}
          />
        </div>
        {{#if this.contract.name}}
          <div class="flex gap-3 text-center">
            <button
              {{on "click" (fn this.onView "contract" this.contract.file)}}
              class="text-info edit"
            >
              <i class="ti ti-eye text-lg"></i>
            </button>
            <button {{on "click" (fn this.onDelete "contract-file-upload")}} class="text-red-500">
              <i class="ti ti-trash text-lg"></i>
            </button>
          </div>
        {{/if}}
      </div>

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            tripPromise: Promise<Trip>;
        };
    };
}>(template(`<Documents @tripPromise={{@model.tripPromise}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
