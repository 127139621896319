import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { type FormData } from 'ember-headless-form';
import RouteTemplate from 'ember-route-template';
import ClientFormComponent from 'vfc-admin/components/forms/clientForm';
import { ComboboxItem } from 'vfc-admin/components/inputs/combobox/combobox';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import type { Client, ClientForm } from 'vfc-admin/models/client';
import type { School } from 'vfc-admin/models/school';
import type ClientService from 'vfc-admin/services/client-service';
import type { ClientRelation } from 'vfc-admin/types/trip';
interface ClientEditSignature {
    Element: HTMLDivElement;
    Args: {
        clientPromise: Promise<Client>;
        schoolsPromise: Promise<School[]>;
        clientRelationsPromise: Promise<ClientRelation[]>;
    };
}
let ClientEditComponent = class ClientEditComponent extends Component<ClientEditSignature> {
    @service
    clientService: ClientService;
    @service
    router: RouterService;
    clientForm: ClientForm;
    schools: School[];
    clientRelations: ClientRelation[];
    get clientResource() {
        return awaitPromise<ClientForm>(Promise.all([
            this.args.clientPromise,
            this.args.schoolsPromise,
            this.args.clientRelationsPromise
        ]).then(([client1, schools1, clientRelations1])=>{
            const clientForm1 = {
                ...client1,
                clientRelationId: client1.clientRelation.id,
                schoolId: client1.school.id
            } as ClientForm;
            this.clientForm = clientForm1;
            this.schools = schools1;
            this.clientRelations = clientRelations1;
            return this.clientForm;
        }));
    }
    get schoolsData(): ComboboxItem[] {
        return this.schools.map((school1)=>new ComboboxItem(`${school1.name} (${school1.province})`, school1.id));
    }
    get clientRelationsData(): ComboboxItem[] {
        return this.clientRelations.map((clientRelation1)=>new ComboboxItem(clientRelation1.name, clientRelation1.id));
    }
    @action
    async onSubmit(data1: FormData<ClientForm>) {
        try {
            await this.clientService.update(this.clientForm.id, data1);
            this.router.transitionTo('authenticated.clients');
        } catch (error1) {
            console.log(error1);
        }
    }
    static{
        template(`
    {{#if this.clientResource.isPending}}
      Loading
    {{else if this.clientResource.isSuccess}}
      <div class="card">
        <div class="col-span-12">
          <div class="border-b border-light-dark py-4 px-6 flex items-center justify-between">
            <h5 class="card-title mb-0">Client: {{this.clientForm.name}}</h5>
          </div>
        </div>

        <div class="card-body">
          <ClientFormComponent
            @onSubmit={{this.onSubmit}}
            @client={{this.clientForm}}
            @schools={{this.schoolsData}}
            @clientRelations={{this.clientRelationsData}}
          />
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            clientPromise: Promise<Client>;
            schoolsPromise: Promise<School[]>;
            clientRelationsPromise: Promise<ClientRelation[]>;
        };
    };
}>(template(`
    <ClientEditComponent
      @clientPromise={{@model.clientPromise}}
      @schoolsPromise={{@model.schoolsPromise}}
      @clientRelationsPromise={{@model.clientRelationsPromise}}
    />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
