import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { get } from '@ember/helper';
import { service } from '@ember/service';
import { headlessTable } from 'ember-headless-table';
import { forColumn, Metadata } from 'ember-headless-table/plugins/metadata';
import RouteTemplate from 'ember-route-template';
import TableSkeletonLoader from 'vfc-admin/components/ui/table-skeleton-loader';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { formatDate } from 'vfc-admin/utils/date-utils';
import type { TOC } from '@ember/component/template-only';
import type Owner from '@ember/owner';
import type { Args } from '@glimmer/component/-private/component';
import type { Column, Row } from 'ember-headless-table';
import type { IntlService } from 'ember-intl';
import type ThemeService from 'vfc-admin/services/theme-service';
import type { Trip } from 'vfc-admin/services/trip-service';
import type { TODO } from 'vfc-admin/utility-types';
interface TripIncompleteSignature {
    Element: HTMLDivElement;
    Args: {
        trips: Promise<Array<Trip>>;
    };
}
const INTL_COLUMNS_PREFIX = 'page.trips.current-course.table.columns';
const isFormatDate = (column1: Column<Trip>)=>{
    return forColumn(column1, 'formatDate');
};
const ContactCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="flex flex-col">
    <span class="font-semibold">{{get @row.data @column.key}}</span>
    <span class="text-xs">{{get @row.data "client.email"}}</span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DestinationCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="flex gap-3 items-center">
    <div>
      <img src="{{get @row.data 'image'}}" class="h-10 w-10 rounded-full" />
    </div>
    <div>
      <h6 class="mb-1">{{get @row.data @column.key}}</h6>
      <p class="text-xs">
        {{get @row.data "destination.city.name"}}
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a href="/trips/incomplete/{{get @row.data 'id'}}" class="text-info edit">
      <i class="ti ti-pencil text-lg"></i>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let TripIncompleteComponent = class TripIncompleteComponent extends Component<TripIncompleteSignature> {
    @service
    themeService: ThemeService;
    @service
    intl: IntlService;
    get tripsResource() {
        return awaitPromise<Array<Trip>>(this.args.trips);
    }
    tripsTable = headlessTable<Trip>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.reference`),
                    key: 'reference'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.school`),
                    key: 'infoTmp.schoolName'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.contact`),
                    key: 'infoTmp.clientName',
                    Cell: ContactCellComponent
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.destination`),
                    key: 'destination.name',
                    Cell: DestinationCellComponent
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.checkIn`),
                    key: 'checkIn',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.checkOut`),
                    key: 'checkOut',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.DEFAULT_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent
                }
            ],
        data: ()=>{
            return this.tripsResource.result ?? [];
        }
    });
    constructor(owner1: Owner, args1: Args<TripSignature>){
        super(owner1, args1);
    }
    static{
        template(`
    <div class="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
      <div class="card">
        <div class="card-body">
          <div class="sm:flex items-center justify-between mb-6">
            <div>
              <h5 class="card-title">Viajes - Incompletos</h5>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
              <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="overflow-hidden">
                  <table
                    class="min-w-full divide-y divide-border dark:divide-darkborder"
                    {{this.tripsTable.modifiers.container}}
                  >
                    <thead>
                      <tr>
                        {{#each this.tripsTable.columns as |column|}}
                          <th
                            scope="col"
                            class="text-left rtl:text-right p-4 ps-0 font-semibold text-dark dark:text-white"
                          >
                            {{column.name}}
                          </th>
                        {{/each}}
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-border dark:divide-darkborder">
                      {{#if this.tripsResource.isPending}}
                        <TableSkeletonLoader @rows={{8}} @columns={{7}} @columnClass={{"py-3"}} />
                      {{else if this.tripsResource.isError}}
                        <tr class="text-center text-red-500">
                          <td colspan="7">
                            <div>Unexpected error has happened</div>
                            <div class="text-sm">{{this.tripsResource.error.message}}</div>
                          </td>
                        </tr>
                      {{else}}
                        {{#each this.tripsTable.rows as |row|}}
                          <tr>
                            {{#each this.tripsTable.columns as |column|}}
                              <td class="text-dark dark:text-darklink text-base font-light py-3">
                                {{#if column.Cell}}
                                  <column.Cell @row={{row}} @column={{column}} />
                                {{else}}
                                  {{#let (isFormatDate column) as |formatDateValue|}}
                                    {{#if formatDateValue}}
                                      {{formatDate (column.getValueForRow row) formatDateValue}}
                                    {{else}}
                                      {{column.getValueForRow row}}
                                    {{/if}}
                                  {{/let}}
                                {{/if}}
                              </td>
                            {{/each}}
                          </tr>
                        {{/each}}
                      {{/if}}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            trips: Promise<Array<TODO>>;
        };
    };
}>(template(`<TripIncompleteComponent @trips={{@model.trips}} />`, {
    eval () {
        return eval(arguments[0]);
    }
}));
