import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type RouterService from '@ember/routing/router-service';
import type { TripForm } from 'vfc-admin/types/trip';
export interface TripFormComponentSignature {
    Element: HTMLElement;
    Args: {
        tripForm?: TripForm;
        onResetForm?: (event: Event) => void;
        onSubmit?: (data: FormData<TripForm>) => void;
    };
}
export default class TripFormComponent extends Component<TripFormComponentSignature> {
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    static{
        template(`
    <HeadlessForm
      @data={{@tripForm}}
      @validateOn="focusout"
      @revalidateOn="input"
      @onSubmit={{@onSubmit}}
      {{on "reset" @onResetForm}}
      as |form|
    >
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="reference" as |field|>
            <field.Label
              class="text-dark dark:text-darklink font-semibold block m-2"
            >Referencia</field.Label>
            <field.Input
              type="text"
              placeholder="Referencia"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
              disabled
            />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="accept_conditions" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Acepta
              condiciones</field.Label>
            <field.Input
              type="text"
              placeholder="Acepta condiciones"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
              disabled
            />
          </form.Field>
        </div>
        <div class="md:col-span-4 col-span-12">
          <form.Field @name="accepted_date" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha y hora</field.Label>
            <field.Input
              type="date"
              placeholder="--"
              class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
              disabled
            />
          </form.Field>
        </div>
      </div>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">Configuración Cliente</legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="modifyClientData" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Modificar
                Datos</field.Label>
              <field.Select class="py-2.5 px-4 pe-9 form-control" as |select|>
                <select.Option @value="all">Todos</select.Option>
                <select.Option @value="all-except-name-and-surname">Todos excepto Nombre y Apellidos</select.Option>
                <select.Option @value="none">Ninguno</select.Option>
              </field.Select>
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">
          <a href="#" class="text-info edit flex items-center gap-2">
            {{! TODO }}
            <span>Datos Cliente</span>
            <i class="ti ti-eye text-lg"></i>
          </a>
        </legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="client_name" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Nombre</field.Label>
              <field.Input
                type="text"
                placeholder="Nombre"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="client_email" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Email</field.Label>
              <field.Input
                type="text"
                placeholder="Email"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="client_relationship" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Quien eres</field.Label>
              <field.Select
                disabled
                class="py-2.5 px-4 pe-9 form-control disabled:bg-slate-50"
                as |select|
              >
                <select.Option @value="Estudiante">Estudiante</select.Option>
                <select.Option @value="Profesor">Profesor</select.Option>
                <select.Option @value="Ambos">Estudiante y Profesor</select.Option>
              </field.Select>
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">
          <a href="#" class="text-info edit flex items-center gap-2">
            {{! TODO }}
            <span>Datos Colegio</span>
            <i class="ti ti-eye text-lg"></i>
          </a>
        </legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="client_school_name" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Nombre</field.Label>
              <field.Input
                type="text"
                placeholder="Nombre"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="client_school_province" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Provincia</field.Label>
              <field.Input
                type="text"
                placeholder="Provincia"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <fieldset class="my-4 border p-4 rounded">
        <legend class="text-dark dark:text-darklink font-semibold text-lg">Datos Viaje</legend>
        <div class="grid grid-cols-12 md:gap-6 gap-y-3">
          {{!
            <div class="md:col-span-4 col-span-12">
              <form.Field @name="releasedDate" as |field|>
                <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha alta</field.Label>
                <field.Input
                  type="text"
                  placeholder="Fecha alta"
                  class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                  disabled
                />
              </form.Field>
            </div>
            }}
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="destination_name" as |field|>
              <field.Label
                class="text-dark dark:text-darklink font-semibold block m-2"
              >Destino</field.Label>
              <field.Input
                type="text"
                placeholder="Destino"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="requestedCheckIn" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Ida
                Formulario</field.Label>
              <field.Input
                type="text"
                placeholder="Fecha Ida Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="checkIn" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Ida
                Viaje</field.Label>
              <field.Input
                type="date"
                placeholder="Fecha Ida Viaje"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="requestedCheckOut" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Vuelta
                Formulario</field.Label>
              <field.Input
                type="text"
                placeholder="Fecha Vuelta Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="checkOut" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Fecha Vuelta
                Viaje</field.Label>
              <field.Input
                type="date"
                placeholder="Fecha Vuelta Viaje"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalRequestedStudents" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Estudiantes Formulario</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Estudiantes Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalStudents" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Estudiantes</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Estudantes"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalRequestedTeachers" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Profesores Formulario</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Profesores Formulario"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalTeachers" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Numero
                Profesores</field.Label>
              <field.Input
                type="number"
                placeholder="Numero Profesores"
                class="py-2.5 px-4 block w-full form-control"
                {{on "invalid" this.validateInputNatively}}
              />
              <field.Errors class="text-xs text-red-500" />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="groupAge" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Edad grupo</field.Label>
              <field.Input
                type="text"
                placeholder="Edad grupo"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="md:col-span-4 col-span-12">
            <form.Field @name="totalPrice" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Presupuesto
                Viaje</field.Label>
              <field.Input
                type="number"
                step="0.01"
                placeholder="Presupuesto Viaje"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
          <div class="col-span-12">
            <form.Field @name="anythingElse" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Algo mas</field.Label>
              <field.Textarea
                placeholder="Algo mas"
                class="py-2.5 px-4 block w-full form-control disabled:bg-slate-50"
                disabled
              />
            </form.Field>
          </div>
        </div>
      </fieldset>
      <div class="grid grid-cols-12 md:gap-6 gap-y-3">
        <div class="col-span-12">
          <form.Field @name="observations" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block m-2">Observaciones
              Internas</field.Label>
            <field.Textarea
              placeholder="Observaciones Internas"
              rows="8"
              class="py-2.5 px-4 block w-full form-control"
            />
            <field.Errors class="text-xs text-red-500" />
          </form.Field>
        </div>
      </div>
      <div class="flex gap-3 justify-end mt-6">
        <button
          class="btn btn-md"
          type="submit"
          disabled={{form.submissionState.isPending}}
        >Submit</button>
        <button class="btn btn-light-error" type="reset">Reset</button>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
