import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type TripPaymentService from 'vfc-admin/services/trip-payment-service';
import type { Trip } from 'vfc-admin/types/trip';

export default class TripPayment extends Route {
  @service declare tripPaymentService: TripPaymentService;

  async model() {
    const { tripId, tripPromise } = this.modelFor('authenticated.trips.current-course.trip') as {
      tripId: string;
      tripPromise: Promise<Trip>;
    };

    const paymentScheduledPromise = this.tripPaymentService.findAllScheduled(tripId);
    const paymentPromise = this.tripPaymentService.findAll(tripId);

    return { tripId, tripPromise, paymentScheduledPromise, paymentPromise };
  }
}
