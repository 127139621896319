import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RouteTemplate from 'ember-route-template';
import { TrackedObject } from 'tracked-built-ins';
import type UserService from 'vfc-admin/services/user-service';
import type { TODO } from 'vfc-admin/utility-types';
type UserFormData = {
    username: string;
    password: string;
};
interface LoginRouteSignature {
    Args: {
        model: void;
    };
}
let LoginRouteComponent = class LoginRouteComponent extends Component<LoginRouteSignature> {
    @service
    session: TODO;
    @service
    userService: UserService;
    data = new TrackedObject<UserFormData>();
    @action
    async onSubmit(event1: Event) {
        event1.preventDefault();
        try {
            await this.session.authenticate('authenticator:token', this.data.username, this.data.password);
        } catch (error1) {
            // eslint-disable-next-line no-console
            console.log(error1);
        }
    }
    @action
    onInput({ currentTarget: formElement1 }: {
        currentTarget: EventTarget | null;
    }) {
        let formData1 = new FormData(formElement1 as HTMLFormElement);
        this.data = Object.fromEntries(formData1.entries()) as UserFormData;
    }
    static{
        template(`
    <main class="h-screen w-full bg-lightprimary">
      <div class="h-full w-full flex justify-center items-center">
        <div class="flex justify-center w-full">
          <div class="xl:w-2/6 w-full">
            <div class="max-w-[460px] px-3 mx-auto">
              <div class="card">
                <div class="card-body">
                  <div class="mx-auto text-center mb-12">
                    <div class="flex justify-center">
                      <div class="brand-logo flex items-center">
                        <img src="/images/logos/logo.svg" alt="Logo" />
                      </div>
                    </div>
                  </div>
                  <form {{on "submit" this.onSubmit}} {{on "input" this.onInput}}>
                    <div class="flex flex-col gap-4 mt-4">
                      <div>
                        <label class="text-dark dark:text-darklink font-semibold mb-2 block">
                          Usuario
                        </label>
                        <input required type="text" class="form-control py-2" name="username" />
                      </div>
                      <div>
                        <label class="text-dark dark:text-darklink font-semibold mb-2 block">
                          Password
                        </label>
                        <input required type="password" class="form-control py-2" name="password" />
                      </div>
                      <button class="btn btn-md py-3">Sign In</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(LoginRouteComponent);
