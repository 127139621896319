import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type Owner from '@ember/owner';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { TODO } from 'vfc-admin/utility-types';

export type MyProfileType = {
  username?: string;
  email?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
};

export default class UserService extends Service {
  @service session: TODO;
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  #username!: string;
  #email!: string;

  constructor(owner: Owner) {
    super(owner);
    this.#username = this.session.data.authenticated.username;
    this.#email = this.session.data.authenticated.email;
  }

  get username(): string {
    return this.#username;
  }

  get email(): string {
    return this.#email;
  }

  get userProfile() {
    let userProfile: Promise<MyProfileType> | undefined;

    if (this.username) {
      const userProfileString = sessionStorage.getItem(`${this.username}.userProfile`);

      if (userProfileString) {
        userProfile = new Promise<MyProfileType>((resolve) =>
          resolve(JSON.parse(userProfileString))
        );
      }
    }

    if (!userProfile) {
      userProfile = this.authenticatedHttpClient.GET<MyProfileType>({
        url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}/user/profile`,
      });

      userProfile.then((userProfile) =>
        sessionStorage.setItem(`${this.username}.userProfile`, JSON.stringify(userProfile))
      );
    }

    return userProfile;
  }

  clearUserProfile() {
    sessionStorage.removeItem(`${this.username}.userProfile`);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
