import { tracked } from '@glimmer/tracking';

export class TripPaymentScheduled {
  id!: number;
  tripId!: number;
  date!: Date;
  description!: string;
  amount!: number;
  paidAmount!: number;
  pendingAmount!: number;
  paid!: boolean;
  observations?: string;
  createdAt!: string;
  updatedAt!: string;
  partialPayments!: TripPaymentPartial[];

  static parse(data: any) {
    const payment = new TripPaymentScheduled();
    payment.id = data.id;
    payment.tripId = data.tripId;
    payment.date = data.date;
    payment.description = data.description;
    payment.amount = data.amount;
    payment.pendingAmount = TripPaymentScheduled.calculatePendingAmount(data);
    payment.paidAmount = payment.amount - payment.pendingAmount;
    payment.paid = data.paid;
    payment.observations = data.observations;
    payment.createdAt = data.createdAt;
    payment.updatedAt = data.updatedAt;
    payment.partialPayments = []; // TODO add partial payments

    return payment;
  }

  static calculatePendingAmount(data: any): number {
    let pendingAmount: number = 0;

    pendingAmount = data.partialPayments.reduce(
      (total: number, partialPayment: any) => total - partialPayment.amount,
      data.amount
    );

    return pendingAmount;
  }
}

export type TripPaymentScheduledForm = Pick<
  TripPaymentScheduled,
  'id' | 'description' | 'date' | 'amount' | 'observations'
>;

export class TripPayment {
  id!: number;
  tripId!: number;
  date!: Date;
  @tracked amount: number = 0;
  concept!: string;
  method!: string;
  observations?: string;
  createdAt!: string;
  updatedAt!: string;
  partialPayments!: TripPaymentPartial[];

  static parse(data: any) {
    const payment = new TripPayment();

    payment.id = data.id;
    payment.tripId = data.tripId;
    payment.date = data.date;
    payment.amount = data.amount;
    payment.concept = data.concept;
    payment.observations = data.observations;
    payment.createdAt = data.createdAt;
    payment.updatedAt = data.updatedAt;
    payment.partialPayments = data.partialPayments;

    return payment;
  }

  toJSON() {
    return {
      id: this.id,
      tripId: this.tripId,
      date: this.date,
      amount: this.amount,
      concept: this.concept,
      method: this.method,
      observations: this.observations,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      partialPayments: this.partialPayments,
    };
  }
}

export type TripPaymentForm = Pick<
  TripPayment,
  'id' | 'concept' | 'date' | 'method' | 'amount' | 'observations' | 'partialPayments'
>;

export class TripPaymentPartial {
  declare tripPaymentScheduledId: number;
  @tracked
  declare amount: number;
  declare createdAt: string;
  declare updatedAt: string;

  constructor(amount: number) {
    this.amount = amount;
  }
}

export class TripPaymentPartialExtended extends TripPaymentPartial {
  declare tripPaymentScheduledId: number;
  declare date: Date;
  declare description: string;
  declare pendingAmount: number;

  constructor(
    tripPaymentScheduledId: number,
    date: Date,
    description: string,
    pendingAmount: number,
    amount: number
  ) {
    super(amount);
    this.tripPaymentScheduledId = tripPaymentScheduledId;
    this.date = date;
    this.pendingAmount = pendingAmount;
    this.description = description;
  }
}
