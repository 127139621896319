import Service from '@ember/service';
import { service } from '@ember/service';

import config from 'vfc-admin/config/environment';

import type { IncompleteTrip } from 'vfc-admin/models/schemas/IncompleteTrip';
import type AuthenticatedHttpClient from 'vfc-admin/services/auth/http-client';
import type { Trip } from 'vfc-admin/types/trip';
import type { TODO } from 'vfc-admin/utility-types';

export default class TripService extends Service {
  @service('auth/http-client') declare authenticatedHttpClient: AuthenticatedHttpClient;

  static ENDPOINTS = {
    incomplete: '/trip/:id/incomplete',
    findAll: '/trip',
    get: '/trip/:id',
    update: '/trip/:id',
  };

  currentCourse(): Promise<TODO> {
    const filters = new URLSearchParams({ state: 'inprogress,closed' });

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  update(id: string, body: TODO): Promise<Trip> {
    const endpoint = TripService.ENDPOINTS.update.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.PUT<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  get(id: string): Promise<TODO> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
    });
  }

  findAll(): Promise<TODO> {
    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}`,
    });
  }

  incomplete(): Promise<TODO> {
    const filters = new URLSearchParams({ state: 'incomplete' });

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  pending(): Promise<TODO> {
    const filters = new URLSearchParams({ state: 'pending' });

    return this.authenticatedHttpClient.GET<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${TripService.ENDPOINTS.findAll}?${filters.toString()}`,
    });
  }

  postIncomplete(id: string, body: IncompleteTrip): Promise<TODO> {
    const endpoint = TripService.ENDPOINTS.incomplete.replace(':id', id);
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return this.authenticatedHttpClient.POST<TODO>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}`,
      headers,
      body,
    });
  }

  downloadProforma(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/download/proforma`,
    });
  }

  generateProforma(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/proforma`,
    });
  }

  downloadContract(id: string): Promise<Response> {
    const endpoint = TripService.ENDPOINTS.get.replace(':id', id);

    return this.authenticatedHttpClient.GET<Response>({
      url: `${config.APP.API.ADMIN_FINDECURSO_PREFIX}${endpoint}/download/contract`,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-service': AuthenticatedHttpClient;
  }
}
