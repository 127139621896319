import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import onScrollScreen from 'vfc-admin/modifiers/on-scroll-screen';
import type ThemeService from 'vfc-admin/services/theme-service';
import type { MyProfileType } from 'vfc-admin/services/user-service';
import type UserService from 'vfc-admin/services/user-service';
import type { TODO } from 'vfc-admin/utility-types';
export interface HeaderSignature {
    Element: HTMLElement;
    Args: {
        myProfile: MyProfileType;
    };
}
export default class Header extends Component<HeaderSignature> {
    @tracked
    shadowEnabled = false;
    @service
    session: TODO;
    @service
    themeService: ThemeService;
    @service
    userService: UserService;
    @action
    toggleSidebar(event1: Event) {
        event1.currentTarget.checked = true;
        const body1 = document.body;
        const dataTheme1 = body1.getAttribute('data-sidebartype');
        const sidebarType1 = dataTheme1 === 'full' ? 'mini-sidebar' : 'full';
        body1.setAttribute('data-sidebartype', sidebarType1);
        this.themeService.sidebarType = sidebarType1;
    }
    @action
    onScroll() {
        this.shadowEnabled = document.body.scrollTop > 60 || document.documentElement.scrollTop > 60;
    }
    @action
    logout() {
        this.session.invalidate();
        this.userService.clearUserProfile();
    }
    static{
        template(`
    <header
      class="{{if this.shadowEnabled 'shadow-sm' ''}}
        sticky top-header top-0 inset-x-0 z-[1] flex flex-wrap md:justify-start md:flex-nowrap text-sm bg-white dark:bg-dark"
      {{onScrollScreen this.onScroll}}
    >
      <div class="with-vertical w-full">
        <div class="w-full mx-auto px-4 lg:py-1 py-3 lg:px-4" aria-label="Global">
          <div class="relative md:flex md:items-center md:justify-between">
            <div class="hs-collapse grow md:block">
              <div class="flex justify-between items-center">
                <div class="flex items-center gap-2">
                  <div class="relative">
                    <a
                      class="xl:flex hidden text-xl icon-hover cursor-pointer text-link dark:text-darklink sidebartoggler h-10 w-10 hover:text-primary light-dark-hoverbg justify-center items-center rounded-full"
                      {{on "click" this.toggleSidebar}}
                    >
                      <i class="ti ti-menu-2 relative z-[1]"></i>
                    </a>
                    <!--Mobile Sidebar Toggle -->
                    <div class="sticky top-0 inset-x-0 xl:hidden">
                      <div class="flex items-center">
                        <!-- Navigation Toggle -->
                        <a
                          class="text-xl icon-hover cursor-pointer text-link dark:text-darklink sidebartoggler h-10 w-10 hover:text-primary light-dark-hoverbg flex justify-center items-center rounded-full"
                          data-hs-overlay="#application-sidebar-brand"
                          aria-controls="application-sidebar-brand"
                          aria-label="Toggle navigation"
                          {{on "click" this.toggleSidebar}}
                        >
                          <i class="ti ti-menu-2 relative z-[1]"></i>
                        </a>
                        <!-- End Navigation Toggle -->
                      </div>
                    </div>
                    <!-- End Sidebar Toggle -->
                  </div>
                </div>
                <div class="icon-nav items-center gap-3 lg:gap-4 flex">
                  <!-- Profile DD -->
                  <div
                    class="hs-dropdown [--strategy:absolute] [--adaptive:none] [--placement:top-left] sm:[--trigger:hover] sm:relative group/menu"
                  >
                    <a
                      id="hs-dropdown-hover-event-profile"
                      class="relative hs-dropdown-toggle cursor-pointer align-middle rounded-full group-hover/menu:bg-lightprimary group-hover/menu:text-primary"
                    >
                      <img
                        class="object-cover w-9 h-9 rounded-full"
                        src="/images/profile/user-1.jpg"
                        alt=""
                        aria-hidden="true"
                      />
                    </a>
                    <div
                      class="card hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 mt-2 min-w-max top-auto right-0 rtl:right-auto rtl:left-0 w-full sm:w-[360px] z-[2] hidden"
                      aria-labelledby="hs-dropdown-hover-event-profile"
                      style=""
                    >
                      <div class="card-body">
                        <div class="flex items-center pb-4 justify-between">
                          <h3 class="mb-0 card-title">Perfíl de usuario</h3>
                        </div>
                        <div class="message-body max-h-[450px]" data-simplebar="init">
                          <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                              <div class="simplebar-height-auto-observer">
                              </div>
                            </div>
                            <div class="simplebar-mask">
                              <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                                <div
                                  class="simplebar-content-wrapper"
                                  tabindex="0"
                                  role="region"
                                  aria-label="scrollable content"
                                  style="height: auto; overflow: hidden;"
                                >
                                  <div class="simplebar-content" style="padding: 0px;">
                                    <div class="">
                                      <div class="flex items-center">
                                        <img
                                          src="/images/profile/user-1.jpg"
                                          class="h-20 w-20 rounded-full object-cover"
                                          alt="profile"
                                        />
                                        <div class="ml-4 rtl:mr-4 rtl:ml-auto">
                                          <h5 class="text-base">
                                            {{@myProfile.username}}
                                          </h5>
                                          <p
                                            class="text-xs font-normal text-link dark:text-darklink"
                                          >
                                            Admin
                                          </p>
                                          <span
                                            class="text-sm font-normal flex items-center text-link dark:text-darklink"
                                          >
                                            <i class="ti ti-mail mr-2"></i>
                                            <span>{{@myProfile.email}}</span>
                                          </span>
                                        </div>
                                      </div>

                                      <ul class="mt-10">
                                        <li class="mb-5">
                                          <a class="flex gap-3 items-center group">
                                            <span
                                              class="bg-lightgray dark:bg-darkgray h-12 w-12 flex justify-center items-center rounded-md"
                                            >
                                              <img
                                                src="/images/svgs/icon-account.svg"
                                                class="h-6 w-6"
                                              />
                                            </span>

                                            <div class="">
                                              <h6 class="text-sm mb-1 group-hover:text-primary">
                                                {{t "components.ui.header.user-menu.my-profile"}}
                                              </h6>
                                              <p
                                                class="text-xs text-link dark:text-darklink font-normal"
                                              >
                                                {{t
                                                  "components.ui.header.user-menu.account-settings"
                                                }}
                                              </p>
                                            </div>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: 0px; height: 0px;">
                            </div>
                          </div>
                          <div
                            class="simplebar-track simplebar-horizontal"
                            style="visibility: hidden;"
                          >
                            <div class="simplebar-scrollbar" style="width: 0px; display: none;">
                            </div>
                          </div>
                          <div
                            class="simplebar-track simplebar-vertical"
                            style="visibility: hidden;"
                          >
                            <div class="simplebar-scrollbar" style="height: 0px; display: none;">
                            </div>
                          </div>
                        </div>
                        <div class="mt-5">
                          <a
                            class="btn btn-outline-primary block w-full"
                            {{on "click" this.logout}}
                          >
                            {{t "components.ui.header.user-menu.sign-out"}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
