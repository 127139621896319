import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { HeadlessForm, type FormData } from 'ember-headless-form';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type { TripPaymentForm, TripPaymentPartial, TripPaymentPartialExtended } from 'vfc-admin/models/payments';
import { PaymentMethod } from 'vfc-admin/models/enums/PaymentMethod';
import type { IntlService } from 'ember-intl';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { formatCurrency } from 'vfc-admin/utils/currency-utils';
import { not } from 'ember-truth-helpers';
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { tracked } from '@glimmer/tracking';
export interface TripPaymentFormSignature {
    Element: HTMLElement;
    Args: {
        payment: TripPaymentForm;
        availablePartialPayments: TripPaymentPartialExtended[];
        onSubmit: (data: FormData<TripPaymentForm>) => void;
        onClose: (event: MouseEvent) => void;
    };
}
export default class TripPaymentFormComponent extends Component<TripPaymentFormSignature> {
    @service
    intl: IntlService;
    @tracked
    assignedAmount: number = 0;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    get modalities() {
        let modalities1: {
            title: string;
            value: string;
        }[] = [];
        for(let item1 in PaymentMethod){
            modalities1.push({
                title: this.intl.t(`enum.payment-method.${PaymentMethod[item1]}`),
                value: PaymentMethod[item1] as string
            });
        }
        return modalities1;
    }
    get hasAmount() {
        return this.args.payment.amount > 0;
    }
    get unassignedAmount() {
        return this.args.payment.amount - this.assignedAmount;
    }
    get hasPendingAmount() {
        return this.assignedAmount < this.args.payment.amount;
    }
    get areAssignedAmountsValid() {
        return this.assignedAmount <= this.args.payment.amount;
    }
    @action
    updateAmount(data1: Event) {
        const value1 = parseFloat((data1.target as HTMLInputElement).value);
        if (!Number.isNaN(value1)) {
            let totalAmount1 = value1;
            this.args.availablePartialPayments.forEach((partialPayment1)=>{
                let amount1 = 0;
                if (totalAmount1 > 0) {
                    const pendingAmount1 = partialPayment1.pendingAmount;
                    amount1 = totalAmount1 > pendingAmount1 ? pendingAmount1 : totalAmount1;
                    totalAmount1 -= amount1;
                }
                partialPayment1.amount = amount1;
            });
            this.assignedAmount = value1 - totalAmount1;
        }
    }
    @action
    checkPartialAmount(partialPayment1: TripPaymentPartialExtended, event1: Event) {
        const inputHTML1 = event1.target as HTMLInputElement;
        let value1 = parseFloat(inputHTML1.value);
        const max1 = parseInt(inputHTML1.max);
        if (!Number.isNaN(value1)) {
            if (value1 > max1) {
                value1 = max1;
                inputHTML1.value = inputHTML1.max;
            }
        } else {
            value1 = 0;
            inputHTML1.value = '0';
        }
        partialPayment1.amount = value1;
        this.updateAssignedAmount();
    }
    @action
    updateAssignedAmount() {
        this.assignedAmount = this.args.availablePartialPayments.reduce((total1: number, partialPayment1: any)=>total1 + partialPayment1.amount, 0);
    }
    @action
    onSubmit(data1: FormData<TripPaymentForm>) {
        const partialPayments1: TripPaymentPartial[] = [];
        this.args.availablePartialPayments.forEach((partialPayment1)=>{
            if (partialPayment1.amount > 0) {
                partialPayments1.push({
                    amount: partialPayment1.amount,
                    tripPaymentScheduledId: partialPayment1.tripPaymentScheduledId
                } as TripPaymentPartial);
            }
        });
        data1.partialPayments = partialPayments1;
        this.args.onSubmit(data1);
    }
    static{
        template(`
    <HeadlessForm
      class="space-y-4"
      @data={{@payment}}
      @onSubmit={{this.onSubmit}}
      @validateOn="focusout"
      @revalidateOn="input"
      @dataMode="mutable"
      as |form|
    >
      <div class="grid grid-cols-12 md:gap-6 sm:max-sm:space-y-4">
        <div class="col-span-12 md:col-span-6">
          <form.Field @name="concept" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Concepto
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              maxLength="255"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12 md:col-span-6">
          <form.Field @name="amount" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Importe
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              type="number"
              min="1"
              {{on "input" this.updateAmount}}
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
      </div>
      <div class="grid grid-cols-12 md:gap-6 sm:max-sm:space-y-4">
        <div class="col-span-12 md:col-span-6">
          <form.Field @name="date" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
              Fecha de pago
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Input
              required
              type="date"
              value="{{if @payment.date (formatDate @payment.date 'YYYY-MM-DD')}}"
              {{on "invalid" this.validateInputNatively}}
              class="form-control py-2 {{if field.isInvalid 'border-error'}}"
            />
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
        <div class="col-span-12 md:col-span-6">
          <form.Field @name="method" as |field|>
            <field.Label class="text-dark dark:text-darklink font-semibold block mb-2">
              Modalidad
              <span class="text-red-500">*</span>
            </field.Label>
            <field.Select
              class="py-2.5 px-4 pe-9 form-control"
              required
              {{on "invalid" this.validateInputNatively}}
              as |select|
            >
              <select.Option @value=""> -- Seleccionar modalidad -- </select.Option>
              {{#each this.modalities as |item|}}
                <select.Option @value="{{item.value}}">{{item.title}}</select.Option>
              {{/each}}
            </field.Select>
            <field.Errors class="py-1 text-xs text-red-300" />
          </form.Field>
        </div>
      </div>
      <div class="space-y-4">
        <div class="grid grid-cols-12 gap-4">
          <label class="col-span-8 text-dark dark:text-darklink font-semibold mb-2 block">
            Pagos programados
          </label>
          <label class="col-span-4 text-dark dark:text-darklink font-semibold mb-2 block">
            Importe (€)
          </label>
        </div>
        {{#each this.args.availablePartialPayments as |partialPayment|}}
          <div class="grid grid-cols-12 gap-4">
            <span
              class="col-span-8 py-2.5 px-4 block w-full rounded-md border border-slate-300 bg-slate-50"
            >
              <span class="text-ellipsis overflow-hidden max-w-full text-nowrap inline-block">
                {{partialPayment.description}}
              </span>
              <div>
                {{formatDate partialPayment.date}}
                <span class="px-3">-</span>
                {{formatCurrency partialPayment.pendingAmount}}
              </div>
            </span>
            <input
              class="col-span-4 form-control disabled:bg-slate-50"
              value="{{partialPayment.amount}}"
              type="number"
              disabled={{not this.hasAmount}}
              {{on "input" (fn this.checkPartialAmount partialPayment)}}
              min="0"
              max={{partialPayment.pendingAmount}}
            />
          </div>
        {{/each}}
        {{#if this.hasPendingAmount}}
          <div>
            <span class="text-warning text-md">
              AVISO: Faltan
              {{formatCurrency this.unassignedAmount}}
              sin asignar
            </span>
          </div>
        {{/if}}
        {{#if (not this.areAssignedAmountsValid)}}
          <div>
            <span class="text-error text-md">
              ERROR: El valor de los importes parciales no puede ser mayor al importe del pago
            </span>
          </div>
        {{/if}}
      </div>
      <div>
        <form.Field @name="observations" as |field|>
          <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
            Observaciones
          </field.Label>
          <field.Textarea class="form-control py-2 {{if field.isInvalid 'border-error'}}" />
          <field.Errors class="py-1 text-xs text-red-300" />
        </form.Field>
      </div>
      <div class="flex gap-3 justify-end">
        <button
          class="btn btn-md disabled:pointer-events-none disabled:opacity-50"
          type="submit"
          disabled={{not this.areAssignedAmountsValid}}
        >
          Guardar
        </button>
        <button type="button" {{on "click" @onClose}} class="btn btn-light-error">
          Cancelar
        </button>
      </div>
    </HeadlessForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
