import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type SchoolService from 'vfc-admin/services/trip-service';

export default class School extends Route {
  @service declare schoolService: SchoolService;

  async model() {
    const schoolsPromise = this.schoolService.findAll();

    return { schoolsPromise };
  }
}
