import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type FormData, HeadlessForm } from 'ember-headless-form';
import { type Column, headlessTable, type Row } from 'ember-headless-table';
import { Metadata } from 'ember-headless-table/plugins/metadata';
import RouteTemplate from 'ember-route-template';
import TableComponent from 'vfc-admin/components/ui/table-component/table-component';
import config from 'vfc-admin/config/environment';
import { awaitPromise } from 'vfc-admin/helpers/await-promise';
import { Room } from 'vfc-admin/models/room';
import { isDateGreaterThan } from 'vfc-admin/utils/date-utils';
import { isDateLessThan } from 'vfc-admin/utils/date-utils';
import { formatDate } from 'vfc-admin/utils/date-utils';
import { validateInputNatively } from 'vfc-admin/utils/form-utils';
import type { TOC } from '@ember/component/template-only';
import type RouterService from '@ember/routing/router-service';
import type { IntlService } from 'ember-intl';
import type { RoomResponse } from 'vfc-admin/services/trip-room-service';
import type TripRoomService from 'vfc-admin/services/trip-room-service';
import type TripService from 'vfc-admin/services/trip-service';
const INTL_COLUMNS_PREFIX = 'page.trips.current-course.rooms.table.columns';
const INTL_CELLS_PREFIX = 'page.trips.current-course.rooms.table.cells';
const AddOrEditRoomModal: TOC<{
    Element: HTMLElement;
    Args: {
        room: Room | undefined;
        onSubmit: (data: FormData<Room>) => void;
        onClose: () => void;
        validateInputNatively: (event: Event) => void;
    };
}> = template(`
  <div
    class="fixed inset-0 z-10 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
  >
    <div class="bg-white rounded-lg shadow-xl w-full max-w-md transform transition-all">
      <div class="bg-gray-100 rounded-t-lg px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-800">{{if @room.id "Editar" "Añadir"}}
          Habitación</h2>
      </div>
      <div class="p-6">
        <HeadlessForm
          class="space-y-4"
          @data={{@room}}
          @onSubmit={{@onSubmit}}
          @validateOn="focusout"
          @revalidateOn="input"
          as |form|
        >
          <div>
            <form.Field @name="name" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Titulo
                <span class="text-red-500">*</span>
              </field.Label>
              <field.Input
                type="text"
                required
                maxLength="255"
                {{on "invalid" @validateInputNatively}}
                class="form-control py-2 {{if field.isInvalid 'border-error'}}"
              />
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
          <div>
            <form.Field @name="beds" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Número de camas
                <span class="text-red-500">*</span>
              </field.Label>
              <field.Input
                type="number"
                required
                {{on "invalid" @validateInputNatively}}
                class="form-control py-2 {{if field.isInvalid 'border-error'}}"
              />
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
          <div>
            <form.Field @name="type" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Género
                <span class="text-red-500">*</span>
              </field.Label>
              <field.Select
                required
                {{on "invalid" @validateInputNatively}}
                class="form-control py-2 {{if field.isInvalid 'border-error'}}"
                as |select|
              >
                <select.Option @value="">
                  -- Selecciona una opción --
                </select.Option>
                <select.Option @value="female">
                  Femenino
                </select.Option>
                <select.Option @value="male">
                  Masculino
                </select.Option>
                <select.Option @value="mixed">
                  Mixto
                </select.Option>
              </field.Select>
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
          <div>
            <form.Field @name="observations" as |field|>
              <field.Label class="text-dark dark:text-darklink font-semibold mb-2 block">
                Observaciones
              </field.Label>
              <field.Textarea class="form-control py-2 {{if field.isInvalid 'border-error'}}" />
              <field.Errors class="py-1 text-xs text-red-300" />
            </form.Field>
          </div>
          <div class="flex gap-3 justify-end">
            <button class="btn btn-md" type="submit">Guardar</button>
            <button type="button" {{on "click" @onClose}} class="btn btn-light-error">
              Cancelar
            </button>
          </div>
        </HeadlessForm>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ActionsCellComponent: TOC<{
    Element: HTMLElement;
    Args: {
        row: Row;
        column: Column;
        onAction: (event: Event) => void;
    };
}> = template(`
  <div class="action-btn flex gap-3 text-center">
    <a {{on "click" (fn @onAction @row.data)}} data-action="edit" class="text-info edit">
      <i class="ti ti-edit text-lg"></i>
    </a>
    <a {{on "click" (fn @onAction @row.data)}} data-action="delete" class="text-red-500">
      <i class="ti ti-trash text-lg"></i>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface RoomsTabSignature {
    Element: HTMLDivElement;
    Args: {
        tripId: string;
        tripRoomsPromise: Promise<RoomResponse>;
    };
}
let RoomsTab = class RoomsTab extends Component<RoomsTabSignature> {
    @tracked
    isOpen = false;
    @tracked
    room: Room | undefined;
    @service
    intl: IntlService;
    @service
    tripRoomService: TripRoomService;
    @service
    tripService: TripService;
    @service
    router: RouterService;
    validateInputNatively = (event1: Event)=>validateInputNatively.call(this, event1);
    @action
    toggleModal() {
        this.isOpen = !this.isOpen;
    }
    @action
    onOpen() {
        this.isOpen = true;
    }
    @action
    onClose() {
        this.isOpen = false;
    }
    get tripRoomsResource() {
        return awaitPromise<RoomResponse>(this.args.tripRoomsPromise);
    }
    @action
    async onSave(room1: FormData<Room>) {
        this.onClose();
        // cast to number to avoid validation error
        room1.beds = Number(room1.beds);
        if (room1.id) {
            await this.tripRoomService.updateRoom(this.args.tripId, room1);
        } else {
            await this.tripRoomService.createRoom(this.args.tripId, room1);
        }
        this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
    }
    @action
    async onAction(data1: Room, { target: target1 }: {
        target: HTMLElement;
    }) {
        const triggerEl1 = target1.closest('[data-action]');
        if (!triggerEl1) return;
        const { action: action1 } = triggerEl1.dataset;
        switch(action1){
            case 'edit':
                this.tripRoomsResource?.result?.rooms.forEach((room1)=>{
                    if (room1.id === data1.id) {
                        this.room = new Room(room1);
                        this.onOpen();
                    }
                });
                this.onOpen();
                break;
            case 'delete':
                await this.tripRoomService.deleteRoom(this.args.tripId, data1.id);
                this.router.refresh(); //TODO - improve it to avoid refreshing the whole page
                break;
        }
    }
    @action
    onAddRoom() {
        this.room = new Room();
        this.onOpen();
    }
    @action
    onChangeDateControl(event1: Event) {
        const target1 = event1.target as HTMLInputElement;
        const name1 = target1.name;
        const value1 = target1.value;
        // clear previous error message
        const errorMessageDiv1 = document.querySelector(`#error-message-${name1}`);
        assert('errorMessageDiv is not null', errorMessageDiv1);
        target1.setCustomValidity('');
        errorMessageDiv1.textContent = '';
        if (name1 === 'enableRoomAvailability') {
            const disableRoomAvailabilityDate1 = document.querySelector<HTMLInputElement>('input[name="disableRoomAvailability"]')?.value;
            if (!disableRoomAvailabilityDate1) return;
            if (isDateGreaterThan(value1, disableRoomAvailabilityDate1)) {
                target1.setCustomValidity(this.intl.t('page.trips.current-course.rooms.form.validation.enableRoomAvailability'));
            }
        } else if (name1 === 'disableRoomAvailability') {
            const enableRoomAvailabilityDate1 = document.querySelector<HTMLInputElement>('input[name="enableRoomAvailability"]')?.value;
            if (!enableRoomAvailabilityDate1) return;
            if (isDateLessThan(value1, enableRoomAvailabilityDate1)) {
                target1.setCustomValidity(this.intl.t('page.trips.current-course.rooms.form.validation.disableRoomAvailability'));
            }
        }
        if (!target1.checkValidity()) {
            errorMessageDiv1.textContent = target1.validationMessage;
            return;
        }
        //TODO send to backend
        const update1 = {};
        update1[name1] = new Date(value1);
        this.tripService.update(this.args.tripId, update1);
    }
    roomsTable = headlessTable<Room[]>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.title`),
                    key: 'name'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.gender`),
                    key: 'type',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                i18n: (value1: string)=>`${INTL_CELLS_PREFIX}.gender.${value1}`
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.numberOfBeds`),
                    key: 'beds'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.numberOfOccupants`),
                    key: 'occupants'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.observations`),
                    key: 'observations'
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.actions`),
                    Cell: ActionsCellComponent
                }
            ],
        data: ()=>{
            return (this.tripRoomsResource?.result?.rooms ?? []).map((room1)=>{
                room1.occupants = room1.students.length;
                return room1;
            });
        }
    });
    static{
        template(`
    {{#if this.isOpen}}
      <AddOrEditRoomModal
        @room={{this.room}}
        @onSubmit={{this.onSave}}
        @onClose={{this.onClose}}
        @validateInputNatively={{this.validateInputNatively}}
      />
    {{/if}}

    <form class="flex space-x-3 my-10">
      <div class="flex flex-col">
        <div class="flex gap-2">
          <label for="enableRoomAvailability" class="block text-sm font-medium text-gray-700 mb-1">
            Fecha habilitación<br />Control de habitaciones
          </label>
          <input
            type="date"
            {{on "change" this.onChangeDateControl}}
            name="enableRoomAvailability"
            class="px-3 py-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={{if
              @tripRoomsPromise
              (formatDate
                @tripRoomsPromise.result.disableRoomAvailability config.APP.DATE.DATE_TYPE_FORMAT
              )
            }}
          />
        </div>
        <div id="error-message-enableRoomAvailability" class="text-red-500"></div>
      </div>

      <div class="flex flex-col">
        <div class="flex gap-2">
          <label for="disableRoomAvailability" class="block text-sm font-medium text-gray-700 mb-1">
            Fecha limite<br />Control de habitaciones
          </label>
          <input
            type="date"
            {{on "change" this.onChangeDateControl}}
            name="disableRoomAvailability"
            class="px-3 py-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={{if
              @tripRoomsPromise
              (formatDate
                @tripRoomsPromise.result.enableRoomAvailability config.APP.DATE.DATE_TYPE_FORMAT
              )
            }}
          />
        </div>
        <div id="error-message-disableRoomAvailability" class="text-red-500"></div>
      </div>
    </form>

    <fieldset class="my-4 border p-4 rounded">
      <legend class="text-dark dark:text-darklink font-semibold text-lg">
        Habitaciones
        <button
          {{on "click" this.onAddRoom}}
          class="mx-2 btn-md text-sm font-semibold rounded-md border border-primary text-primary hover:bg-primary hover:text-white"
        >
          <i class="ti ti-plus text-base"></i>
          <span class="max-sm:hidden">Añadir habitacion</span>
        </button>
      </legend>

      <TableComponent
        @contentPromise={{this.args.tripRoomsPromise}}
        @contentTable={{this.roomsTable}}
        @onAction={{this.onAction}}
      />

    </fieldset>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate<{
    Args: {
        model: {
            roomsPromise: Promise<RoomResponse>;
            tripId: string;
        };
    };
}>(template(`
    <RoomsTab @tripId={{@model.tripId}} @tripRoomsPromise={{@model.roomsPromise}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
